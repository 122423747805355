import { render, staticRenderFns } from "./MutateAffiliate.vue?vue&type=template&id=598af30b&scoped=true&"
import script from "./MutateAffiliate.vue?vue&type=script&lang=js&"
export * from "./MutateAffiliate.vue?vue&type=script&lang=js&"
import style0 from "./MutateAffiliate.vue?vue&type=style&index=0&id=598af30b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "598af30b",
  null
  
)

export default component.exports