import { render, staticRenderFns } from "./DdexAssetModal.vue?vue&type=template&id=ed14a756&scoped=true&"
import script from "./DdexAssetModal.vue?vue&type=script&lang=js&"
export * from "./DdexAssetModal.vue?vue&type=script&lang=js&"
import style0 from "./DdexAssetModal.vue?vue&type=style&index=0&id=ed14a756&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed14a756",
  null
  
)

export default component.exports