export default {
  name: "honkbalsoftbal.tv",

  defaultLanguage: "nl",

  use2FA: true,

  slideEnabled: true,

  enableAds: false,

  enableShopping: false,

  enableAffiliates: false,

  enablePromoCodes: false,

  hasPayingCustomers: true,

  allowInsertFromExcel: false,

  allowPartnerContractUpload: false,

  logo: "platform/logo.png",

  thumbor_server: "img.honkbalsoftbal.tv"
}
